exports.onClientEntry = () => {
  const queryData = []

  const [, query] = window.location.search.split("?")

  if (query) {
    const fields = query.split("&")
    fields.forEach(field => {
      const [key, value] = field.split("=")
      queryData.push({ key, value })
    })
  }

  if (queryData.length) {
    sessionStorage.setItem("queryData", JSON.stringify(queryData))
  }
}
